import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

import FirebaseAuth from "../../services/auth";

export default () => {
  useEffect(() => {
    FirebaseAuth.logout();
  }, []);

  return <LayoutSplashScreen />;
};