import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useToasts } from 'react-toast-notifications';

import Spinner from 'react-bootstrap/Spinner';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody
} from "../../partials/content/Portlet";

import API from './../../services/api';

export default ({match}) => {
    const [showLoading, setLoading] = useState(true);
    const [isOnline, setIsOnline] = useState(null);
    const [device, setDevice] = useState(null);
    const { addToast } = useToasts();
    const deviceID = match.params.id;

    useEffect(() => {
        console.log({
            deviceID: deviceID,
            showLoading: showLoading,
            isOnline: isOnline,
            device: device
        });

        API.devices.getOne(deviceID).then(item => {
            setDevice(item);
            setIsOnline(_.get(item, '_conn.online', false));
            setLoading(false);
        });
    }, [deviceID]);

    /*useEffect(() => {
        if (isOnline) {
            createProxy();
        } else {
            setProxy(false);
        }
    }, [isOnline]);*/

    /*useEffect(() => {
        if (proxy === null) {
            setLoading(true);
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [proxy]);*/

    /*useEffect(() => {
        console.log({ proxy: proxy });
    }, [proxy]);
    useEffect(() => {
        console.log({ loading: showLoading });
    }, [showLoading]);
    useEffect(() => {
        console.log({ isOnline: isOnline });
    }, [isOnline]);*/

    /*const createProxy = () => {
        API.devices.create.proxy(deviceID).then(data => {
            setProxy(data);
        });
    };*/

    const handleClick = async (target) => {
        setLoading(true);
        API.devices.proxy(device._id).then(data => {
            let url = data;
            switch (target) {
                case "webvisu":
                    url += 'webvisu/webvisu.htm';
                    break;
                case "dataplotter":
                    url += 'dataplotter/dataplotter.html';
                    break;
                default:
                    break;
            }
            window.open(url, '_blank', 'noreferrer');
        }).catch(err => {
            addToast("Device proxy could not be created", { appearance: 'warning' });
        }).then(() => setLoading(false));
    };

    return (
        <React.Fragment>
            <LayoutSubheader title={_.get(device, 'data.desc', deviceID)} />
            <Portlet>
                <PortletBody fit={true}>
                    <Row className="row-col-separator-xl">
                        <Col xl={12} className="text-center p-5">
                        {(showLoading) ? (
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        ) : (
                            (isOnline) ? (
                                <section>
                                    {/*<Frame src={proxy} width="1024px" height="600px" style={{ border: "0" }} />*/}
                                    {device.data.proxy.map((proxy, n) => (
                                        <p id={n}><button className="btn btn-info" onClick={() => handleClick(proxy[0])}>{proxy[1]}</button></p>
                                    ))}
                                </section>
                            ) : (
                                <h3>This device is offline</h3>
                            )
                        )}
                        </Col>
                    </Row>
                </PortletBody>
            </Portlet>
        </React.Fragment>
    )
};