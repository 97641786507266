/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import objectPath from "object-path";
import { useLayoutContext } from "../LayoutContext";
import { ReactComponent as SortNum1Icon } from "../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg";
import { QuickActions } from "./components/QuickActions";
import * as builder from "../../ducks/builder";
// import BreadCrumbs from "./components/BreadCrumbs";

const SubHeader = ({ subheaderClasses, subheaderContainerClasses, ...props }) => {
  const { subheader: { title, menu } } = useLayoutContext();

  return (
    <div className={`kt-subheader kt-grid__item ${subheaderClasses}`} id="kt_subheader">
      <div className={`kt-container ${subheaderContainerClasses}`}>
        {/*Subheader Main*/}
        <div className="kt-subheader__main">
          <React.Fragment>
            <h3 className="kt-subheader__title">{title}</h3>
            {/*<BreadCrumbs items={breadcrumb} />*/}
          </React.Fragment>
        </div>

        {(menu) ? (
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              {menu}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
};

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, "header.menu.self.display"),
  layout: objectPath.get(store.builder.layoutConfig, 'subheader.layout'),
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  clear: objectPath.get(store.builder.layoutConfig, 'subheader.clear'),
  isOpen: false,
  subheaderClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true
  }),
  subheaderContainerClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true
  }),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
