import _ from 'lodash';
import moment from 'moment';

let debug = false;

const Storage = {
    _s: {},
    available: false,
    get: (key) => {
        if (Storage.available) {
            return window.localStorage.getItem(key) || undefined;
        } else {
            return Storage._s[key] || undefined;
        }
    },
    set: (key, value = moment().unix()) => {
        if (Storage.available) {
            return window.localStorage.setItem(key, value);
        } else {
            return Storage._s[key] = value;
        }
    },
    remove: (key) => {
        if (Storage.available) {
            return window.localStorage.removeItem(key);
        } else {
            return delete Storage._s[key];
        }
    },
    search: {
        filter: {
            get: (category) => {
                let key = 'search:filter';
                let storage = JSON.parse(Storage.get(key) || '{}');
                return (storage[category] || null);
            },
            set: (category, value) => {
                let key = 'search:filter';
                let storage = JSON.parse(Storage.get(key) || '{}');
                storage[category] = value;
                Storage.set(key, JSON.stringify(storage));
            },
            remove: (category) => {
                let key = 'search:filter';
                let storage = JSON.parse(Storage.get(key) || '{}');
                delete storage[category];
                Storage.set(key, JSON.stringify(storage));
            }
        }
    },
    job: {
        visit: {
            get: (id) => {
                let key = 'visits:jobs';
                let storage;
                if (!id) {
                    storage = JSON.parse(Storage.get(key) || '[]');
                    return storage;
                } else {
                    storage = JSON.parse(Storage.get(key) || '[]');
                    return (_.indexOf(storage, id) !== -1) ? true : false;
                }
            },
            set: (id) => {
                let key = 'visits:jobs';
                let storage = JSON.parse(Storage.get(key) || '[]');
                storage.push(id);
                Storage.set(key, JSON.stringify(_.takeRight(storage, 200)));
            }
        }
    },
    newsletter: {
        hidden: {
            get: () => {
                let key = 'settings:newsletter:hidden';
                return (Storage.get(key)) ? true : false;
            },
            set: (value) => {
                let key = 'settings:newsletter:hidden';
                if (value) {
                    Storage.set(key, +new Date());
                } else {
                    Storage.remove(key);
                }
            }
        },
        subscribed: {
            get: () => {
                let key = 'settings:newsletter:subscribed';
                return (Storage.get(key)) ? true : false;
            },
            set: (value) => {
                let key = 'settings:newsletter:subscribed';
                if (value) {
                    Storage.set(key, +new Date());
                } else {
                    Storage.remove(key);
                }
            }
        }
    }
}

function storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}

if (storageAvailable('localStorage')) {
    log('available');
    Storage.available = true;
} else {
    log('not available');
    Storage.available = false;
}

export default Storage;

function log(message) {
    if (debug) console.log("[Storage]", message);
}