import * as firebase from "firebase/app";
import "firebase/firestore";
import async from 'async';
import _ from 'lodash';

import auth from './auth';

let db = firebase.firestore();

export default {
    devices: {
        list: (live, callback) => {
            if (live) {
                return db.collection("devices").onSnapshot(data => {
                    let items = [];
                    data.docChanges().forEach(change => {
                        let item = {
                            _id: change.doc.id,
                            ...change.doc.data()
                        };
                        items.push([change.type, item]);
                    });
                    callback(items);
                });
            } else {
                return db.collection("devices").get().then(doc => {
                    return doc.data();
                });
            }
        },
            one: (id, live, callback) => {
                if (live) {
                    return db.collection("devices").doc(id).onSnapshot(item => {
                        callback(item.data());
                    });
                } else {
                    return db.collection("devices").doc(id).get().then(doc => {
                        return doc.data();
                    });
                }
            },
                connections: {
            listen: ({ id, type }, callback) => {
                if (id) {
                    return db.collection("connections").doc('devices').collection(type).doc(id).onSnapshot(item => {
                        callback(item.data());
                    });
                } else {
                    return db.collection("connections").doc('devices').collection(type).onSnapshot(data => {
                        let items = [];
                        data.docChanges().forEach(change => {
                            let item = {
                                _id: change.doc.id,
                                ...change.doc.data()
                            };
                            items.push([change.type, item]);
                        });
                        callback(items);
                    });
                }
            }
        }
    }
};