import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Devices from "./Devices";
import { LayoutSplashScreen } from "../../_metronic";

const ReactBootstrapPage = lazy(() =>
  import("./home/react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/devices" to="/dashboard" />

        <Route path="/dashboard" component={Dashboard} />
        <Route path="/devices" component={Devices} />
        
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
};