import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./home/Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./home/docs/DocsPage";
import { LayoutSplashScreen } from "../../_metronic";

import DevicesProxy from "./devices/Info";

const ReactBootstrapPage = lazy(() =>
    import("./home/react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
    return (
        <Switch>
            <Route path="/devices/:id" component={DevicesProxy} />
        </Switch>
    );
};