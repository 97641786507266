import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import _ from "lodash";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useToasts } from 'react-toast-notifications';
import { AuthContext } from "../../../_metronic";

import Spinner from 'react-bootstrap/Spinner';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { LayoutSubheader } from "../../../_metronic/layout/LayoutContext";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";

import useConnections from './../../services/connections';

import { DeviceItem } from "../devices/List";

import Firebase from './../../services/firebase';
import API from './../../services/api';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

const Info = ({ customer }) => {
    return (
        <React.Fragment>
            <div className="kt-widget1__item ng-star-inserted">
                <div className="kt-widget1__info">
                    <h3 className="kt-widget1__title">{customer._id}</h3>
                    <span className="kt-widget1__desc">{customer.status}</span>
                </div>
            </div>
        </React.Fragment>
    );
};

const Devices = ({ customer }) => {
    let [devices, setDevices] = useState([]);
    let connections = useConnections();

    useEffect(() => {
        API.devices.getAll({customer: customer._id}).then(data => {
            setDevices(data);
        });
    }, []);

    useEffect(() => {
        if (_.isEmpty(devices)) return;
        let allDevices = [...devices];
        allDevices.forEach(item => {
            let id = item._id;
            if (connections[id]) {
                item._conn = { ...connections[id] };
            } else {
                delete item._conn;
            }
        });
        setDevices(allDevices);
    }, [connections]);

    return (
        <Portlet className="mb-0">
            <PortletBody>
                <div className="kt-widget5">
                    {(devices.length === 0) ? (
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : devices.map(device => <DeviceItem key={device._id} device={device} />)}
                </div>
            </PortletBody>
        </Portlet>
    )
};

const HeaderMenu = ({ match, reload, customer }) => {
    let customerID = match.params.id;
    if (!customer) return null;
    return (
        <React.Fragment>
            <Link to={`/customers/${customerID}/edit`} className="btn btn-label-primary btn-bold btn-icon-h">
                Edit
            </Link>
            <Button className="btn btn-label-danger btn-bold btn-icon-h">
                Disable
            </Button>
        </React.Fragment>
    )
};

export default ({ match }) => {
    const { user } = useContext(AuthContext);
    const [customer, setCustomer] = useState(null);
    const [customerID, setCustomerID] = useState(null);

    const reloadMainInfo = () => {
        API.customers.getOne(customerID).then(item => {
            setCustomer(item);
        });
    };

    useEffect(() => {
        user.me.then(me => {
            setCustomerID(me._customers[0]._id);
        });
    }, [user]);

    useEffect(() => {
        if (customerID) reloadMainInfo();
    }, [customerID]);

    return (
        <React.Fragment>
            <LayoutSubheader title={_.get(customer, 'data.name', customerID)} />
            <Portlet>
                <PortletBody fit={true}>
                    <Row className="row-no-padding row-col-separator-xl">
                        {(!customer) ? (
                            <Col xl={12}>
                                <div className="kt-widget1">
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            </Col>
                        ) : (
                                <React.Fragment>
                                    <Col lg={4}>
                                        <div className="kt-widget1">
                                            <Info customer={customer} />
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <Devices customer={customer} />
                                    </Col>
                                </React.Fragment>
                            )}
                    </Row>
                </PortletBody>
            </Portlet>
        </React.Fragment>
    )
};